import {React, useState, useEffect} from 'react';
import Title from './individual/Title';
import Spacer from './individual/Spacer';
import Button from './individual/Button';
import { useParams, useHistory} from "react-router-dom";
import {
  PlusIcon,
  XIcon
} from '@heroicons/react/solid'

export default function AddClass() {
  const [newClient, setNewClient] = useState('')
  const [newID, setNewID] = useState('')
  const [schools, setSchools] = useState([])
  let history = useHistory();
  const id = useParams()

  if (id.id) {
    setNewID = id.id;
  }

  const addClient = async(type) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('accesstoken') },
        body: JSON.stringify({ klasnaam: newClient })
    };
    const res = await fetch('https://api.leonidas.mylleinformatics.be/class/add/' + newID + '/', requestOptions);
    const data = await res.json();
    if (type === "primary") {
      history.push('/viewClass/' + data)
    } else {
      history.go(0)
    }
  }

  useEffect(() => {
      const getSchools = async() => {
            const res = await fetch('https://api.leonidas.mylleinformatics.be/school/list/simple/', 
            { 
              headers: {'Authorization': 'Bearer ' + localStorage.getItem('accesstoken')}
            }
          )
            const data = await res.json()
            setSchools(data)
      }
      getSchools();
  }, [])

  return ( 
      <div className="mx-24">
      <Spacer height={50} />
      <Title text="Klas toevoegen" type="head" />
      <Spacer height={15} />
      <div>
        <label htmlFor="location" className="block text-sm font-medium text-gray-700">
            Location
        </label>
        <select
            id="location"
            name="location"
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            defaultValue="Selecteer een school..."
            onChange={event => setNewID(event.target.value)}
          >
            <option value="Selecteer een school..." disabled>Selecteer een school...</option>
            {schools.map((school) => (
              <option value={school[0]}>{school[1]}</option>
            ))}
        </select>
        </div>
      <Spacer height={15} />
       <div id="addDefaultClient">
              <label htmlFor="klantnaam" className="block text-sm font-medium text-gray-700">
              Klasnaam
              </label>
              <div className="mt-1">
              <input
                  type="text"
                  name="klantnaam"
                  id="klantnaam"
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  placeholder="Klasnaam"
                  onChange={event => setNewClient(event.target.value)}
              />
              </div>
          </div>
          <Spacer height={15} />
          <div className="float-right">
              <Button text="Opslaan en klas toevoegen" icon={<XIcon className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />} type="secondary" onClick={addClient} />
              <span className="ml-3" />
              <Button text="Opslaan en klas inkijken" icon={<PlusIcon className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />} type="primary" onClick={addClient}/>
          </div>
      </div>
  )
};