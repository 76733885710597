import { Link } from 'react-router-dom'; 

  export default function TableToggle({data, fields, edit, link, removeable, removeIcon, onClick, toggleOff, toggleOn}) {
    return (
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    {fields.map((field, fieldIdx) => (
                      <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {field}
                        </th>
                    ))}
                    {edit && <th
                      scope="col"
                      className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          ACTIE
                        </th>
                    }
                    {toggleOff && <th
                      scope="col"
                      className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          weergeven in lijst
                        </th>
                    }
                  </tr>
                </thead>
                <tbody>
                  {data.map((client, clientIdx) => (
                    <tr key={client[clientIdx]} className={clientIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                      {typeof client[0] === 'string'
                        ? <>
                         <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{client[0]}</td>
                         <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{client[1]}</td>
                        </>
                        : <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{client[1]}</td>
                      }
                      {typeof client[2] !== 'undefined' && <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{client[2]}</td>}
                      {edit && <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <Link to={link + client[0]} className="text-indigo-600 hover:text-indigo-900">
                         Inkijken/aanpassen
                        </Link>
                      </td>}
                      {toggleOff && <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        { client[3] == "1" ?
                            <input
                            id="comments"
                            aria-describedby="comments-description"
                            name="comments"
                            type="checkbox"
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                            onClick={() => toggleOff(client[0])}
                            checked
                          />
                        :
                          <input
                            id="comments"
                            aria-describedby="comments-description"
                            name="comments"
                            type="checkbox"
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                            onClick={() => toggleOn(client[0])}
                          />
                        }
                      </td>}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }