export default function Button({ type, icon, text, onClick, classes }) {
    return (
      <>
      <button type="button" onClick={() => onClick(type)} className={(type === "primary" ? 
      ' shadow-sm text-white bg-indigo-600 hover:bg-indigo-700' 
      : 'text-indigo-700 bg-indigo-100 hover:bg-indigo-200') + 
      " inline-flex items-center px-3 py-2 border border-transparent text-base font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 " + classes}>
       {text}{icon}
      </button>
      </>
    )
  }