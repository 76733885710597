import { React, useState, useEffect } from 'react';
import { Fragment } from 'react'
import {
  PlusIcon,
  CalendarIcon,
  ChevronDownIcon,
  XIcon,
} from '@heroicons/react/solid'
import { Menu, Transition } from '@headlessui/react'
import {useParams} from "react-router-dom";
import Spacer from './individual/Spacer';
import Table from './individual/Table';
import Title from './individual/Title';
import Divider from './individual/Divider';
import { Link, useHistory } from "react-router-dom";
import Button from './individual/Button';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


export default function ViewCartonClass() {
  const id = useParams()
  const [carton, setCarton] = useState([])
  const [cartonSuppl, setCartonSuppl] = useState([])
  const [info, setInfo] = useState([])
  const [pralines, setPralines] = useState([])
  const [pralinesSuppl, setPralinesSuppl] = useState([])

  const [praline, setPraline] = useState("")
  const [pralineAantal, setPralineAantal] = useState("")
  const [pralineGewicht, setPralineGewicht] = useState("250gr")

  const [PralineSuppl, setPralineSuppl] = useState([])
  const [pralineSupplAantal, setPralineSupplAantal] = useState("")

  const [pralineCustom, setPralineCustom] = useState("")
  const [pralineEtiketCustom, setPralineEtiketCustom] = useState("")
  const [pralineGewichtCustom, setPralineGewichtCustom] = useState("250gr")
  const [pralineCustomAantal, setPralineCustomAantal] = useState("")


  let history = useHistory();

  const [effect, setEffect] = useState(0);


  useEffect(() => {
      const getCarton = async() => {
            const res = await fetch('https://api.leonidas.mylleinformatics.be/carton/content/' + id.id + '/', 
            { 
              headers: {'Authorization': 'Bearer ' + localStorage.getItem('accesstoken')}
            }
          )
            const data = await res.json()
            setCarton(data)
      }
      const getCartonSuppl = async() => {
        const res = await fetch('https://api.leonidas.mylleinformatics.be/carton/content/' + id.id + '/suppl/', 
        { 
          headers: {'Authorization': 'Bearer ' + localStorage.getItem('accesstoken')}
        }
      )
        const data = await res.json()
        setCartonSuppl(data)
        }
      const getInfo = async() => {
        const res = await fetch('https://api.leonidas.mylleinformatics.be/carton/info/simple/' + id.id + '/class/', 
        { 
          headers: {'Authorization': 'Bearer ' + localStorage.getItem('accesstoken')}
        }
      )
        const data = await res.json()
        setInfo(data)
     }
     const getPralines = async() => {
        const res = await fetch('https://api.leonidas.mylleinformatics.be/catalogue/list/products/dropdown/', 
        { 
          headers: {'Authorization': 'Bearer ' + localStorage.getItem('accesstoken')}
        }
      )
        const data = await res.json()
        setPralines(data)
     }
     const getProductsSuppl = async() => {
        const res = await fetch('https://api.leonidas.mylleinformatics.be/catalogue/list/supplements/', 
        { 
          headers: {'Authorization': 'Bearer ' + localStorage.getItem('accesstoken')}
        }
      )
        const data = await res.json()
        setPralinesSuppl(data)
      }
      getCarton();
      getInfo();
      getPralines();
      getProductsSuppl();
      getCartonSuppl();


      setPraline("Selecteer een praline...")
      setPralineGewicht("250gr")
      setPralineAantal("")

      setPralineCustom("")
      setPralineEtiketCustom("")
      setPralineCustomAantal("")
      setPralineGewichtCustom("250gr")

      setPralineSuppl("Selecteer een produit supplémentaire...")
      setPralineSupplAantal("")

  }, [effect])

  const removeBox = async(id) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('accesstoken') },
            body: JSON.stringify({ boxid: id })
        };
        const res = await fetch('https://api.leonidas.mylleinformatics.be/carton/box/remove/', requestOptions);
        const data = await res.json();
        if (data === "OK") {
            setEffect(effect + 1)
        }
    }

    const removeSuppl = async(id) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('accesstoken') },
            body: JSON.stringify({ prodid: id })
        };
        const res = await fetch('https://api.leonidas.mylleinformatics.be/carton/box/remove/suppl/', requestOptions);
        const data = await res.json();
        if (data === "OK") {
            setEffect(effect + 1)
        }
    }

    const addDefaultPraline = async() => {
        if (praline && pralineGewicht && pralineAantal) {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('accesstoken') },
                body: JSON.stringify({ praline: praline, gewicht: pralineGewicht, aantal: pralineAantal })
            };
            const res = await fetch('https://api.leonidas.mylleinformatics.be/carton/box/add/' + id.id + '/default/', requestOptions);
            const data = await res.json();
            if (data === "OK") {
                setEffect(effect + 1)
            }
        } else {
            alert("Selecteer een praline...")
        }
    }

    const addProductSuppl = async() => {
        if (PralineSuppl  && pralineSupplAantal) {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('accesstoken') },
                body: JSON.stringify({ prod: PralineSuppl, aantal: pralineSupplAantal })
            };
            const res = await fetch('https://api.leonidas.mylleinformatics.be/carton/box/add/' + id.id + '/suppl/', requestOptions);
            const data = await res.json();
            if (data === "OK") {
                setEffect(effect + 1)
            }
        } else {
            alert("Selecteer een produit supplementaire voor het toevoegen.")
        }
    }

    const addCustomPraline = async() => {
        if (pralineCustom && pralineGewichtCustom && pralineEtiketCustom && pralineCustomAantal) {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('accesstoken') },
                body: JSON.stringify({ praline: pralineCustom, gewicht: pralineGewichtCustom, etiket: pralineEtiketCustom, aantal: pralineCustomAantal})
            };
            const res = await fetch('https://api.leonidas.mylleinformatics.be/carton/box/add/' + id.id + '/custom/', requestOptions);
            const data = await res.json();
            if (data === "OK") {
                setEffect(effect + 1)
            }
        } else {
            alert("Vul de gevraagde velden in...")
        }
    }


    const addCarton = async() => {
        if ("ok" == "ok") {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('accesstoken') },
            };
            const res = await fetch('https://api.leonidas.mylleinformatics.be/carton/add/' + id.client + '/class/', requestOptions);
            const data = await res.json();
            history.push('/carton/view/class/' + data + '/' + id.client)
            setEffect(effect + 1)
        } else {
            alert("Er is een fout opgetreden...")
        }
    }

  return ( 
    <div className="mx-24">
            <Spacer height={50} />
            <div className="lg:flex lg:items-center lg:justify-between">
            <div className="flex-1 min-w-0">
                <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">Karton '<i>{info[1]}</i>' van klas '<i>{info[2]}</i>' <span className="text-grey-300">({info[3]})</span></h2>
            </div>
            <div className="mt-5 flex lg:mt-0 lg:ml-4">
            <span className="sm:ml-3">
                    <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={addCarton}
                    >
                        <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                        Karton toevoegen
                    </button>
                </span>
                {/* Dropdown */}
                <Menu as="span" className="ml-3 relative sm:hidden">
                <Menu.Button className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    More
                    <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5 text-gray-500" aria-hidden="true" />
                </Menu.Button>

                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 -mr-1 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <Menu.Item>
                        {({ active }) => (
                        <a
                            href="#"
                            className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                        >
                            Edit
                        </a>
                        )}
                    </Menu.Item>
                    <Menu.Item>
                        {({ active }) => (
                        <a
                            href="#"
                            className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                        >
                            View
                        </a>
                        )}
                    </Menu.Item>
                    </Menu.Items>
                </Transition>
                </Menu>
            </div>
            </div>
            <Spacer height={15} />
            <Divider />
            <Spacer height={50} />
            <div className="mx-5">
                <Title text="Doos toevoegen aan karton" type="secondary" />
                <Spacer height={15} />
                <div class="grid grid-cols-7 gap-4 border-2 border-gray-300 border-dashed rounded-lg p-5 bg-gray-100">
                    <div className="col-span-4">
                        <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                            Assortiment
                        </label>
                        <select
                            id="location"
                            name="location"
                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                            defaultValue="Selecteer een praline..."
                            onChange={event => setPraline(event.target.value)}
                            value={praline}
                        >
                            <option value="Selecteer een praline..." disabled>Selecteer een praline...</option>
                            {pralines.map((praline) => (
                                <option value={praline[0]}>{praline[1]}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-span-1">
                        <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                            Gewicht
                        </label>
                        <select
                            id="location"
                            name="location"
                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                            defaultValue="250gr"
                            onChange={event => setPralineGewicht(event.target.value)}
                            value={pralineGewicht}
                        >
                            <option>250gr</option>
                            <option>500gr</option>
                            <option>750gr</option>
                            <option>1kg</option>
                            <option>1.5kg</option>
                        </select>
                    </div>
                    <div className="col-span-1">
                        <label htmlFor="aangepastproduct" className="block text-sm font-medium text-gray-700 pb-1">
                         Aantal
                        </label>
                        <input
                              type="text"
                              name="aantalproduct"
                              id="aantalproduct"
                              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md w-1/2"
                              placeholder="Aantal..."
                              onChange={event => setPralineAantal(event.target.value)}
                              value={pralineAantal}
                            />  
                    </div>
                    <Button text="TOEVOEGEN" icon={<PlusIcon className="ml-auto mr-3 h-5 w-5" aria-hidden="true" />} type="primary" classes="h-3/4 w-auto ml-auto mt-auto" onClick={addDefaultPraline} />
                </div>
                <Spacer height={25} />
                <div className="mx-5"><Divider text="OF" /></div>
                <Spacer height={25} />
                <div class="grid grid-cols-7 gap-4 border-2 border-gray-300 border-dashed rounded-lg p-5 bg-gray-100">
                    <div className="col-span-2">
                        <label htmlFor="aangepastproduct" className="block text-sm font-medium text-gray-700 pb-1">
                            Autres
                        </label>
                        <input
                              type="text"
                              name="aangepastproduct"
                              id="aangepastproduct"
                              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md w-1/2"
                              placeholder="Naam..."
                              onChange={event => setPralineCustom(event.target.value)}
                              value={pralineCustom}
                            />  
                    </div>
                    <div className="col-span-2">
                        <label htmlFor="aangepastproduct" className="block text-sm font-medium text-gray-700 pb-1">
                         Etiket
                        </label>
                        <input
                              type="text"
                              name="ettiqueaangepastproduct"
                              id="ettiqueaangepastproduct"
                              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md w-1/2"
                              placeholder="Naam..."
                              onChange={event => setPralineEtiketCustom(event.target.value)}
                              value={pralineEtiketCustom}
                            />  
                    </div>
                    <div className="col-span-1">
                        <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                            Gewicht
                        </label>
                        <select
                            id="location"
                            name="location"
                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                            defaultValue="250gr"
                            onChange={event => setPralineGewichtCustom(event.target.value)}
                            value={pralineGewichtCustom}
                        >
                            <option>250gr</option>
                            <option>500gr</option>
                            <option>750gr</option>
                            <option>1kg</option>
                            <option>1.5kg</option>
                        </select>
                    </div>
                    <div className="col-span-1">
                        <label htmlFor="aangepastproduct" className="block text-sm font-medium text-gray-700 pb-1">
                         Aantal
                        </label>
                        <input
                              type="text"
                              name="aantalproduct"
                              id="aantalproduct"
                              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md w-1/2"
                              placeholder="Aantal..."
                              onChange={event => setPralineCustomAantal(event.target.value)}
                              value={pralineCustomAantal}
                            />  
                    </div>
                    <Button text="TOEVOEGEN" icon={<PlusIcon className="ml-auto mr-3 h-5 w-5" aria-hidden="true" />} type="primary" classes="h-3/4 w-auto ml-auto mt-auto" onClick={addCustomPraline} />
                    <p className="col-span-7 text-xs text-red-500"><b>! PAS OP !</b> Controleer eerst of de praline dat je wilt toevoegen niet reeds in het assortiment staat. Bij het toevoegen van een praline wordt deze in het assortiment geplaatst.</p>
                </div>
                <Spacer height={25} />
                <div className="mx-5"><Divider text="OF" /></div>
                <Spacer height={25} />
                <div class="grid grid-cols-7 gap-4 border-2 border-gray-300 border-dashed rounded-lg p-5 bg-gray-100">
                    <div className="col-span-5">
                        <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                            Produit supplémentaire
                        </label>
                        <select
                            id="location"
                            name="location"
                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                            defaultValue="Selecteer een produit supplémentaire..."
                            onChange={event => setPralineSuppl(event.target.value)}
                            value={PralineSuppl}
                        >
                            <option value="Selecteer een produit supplémentaire..." disabled>Selecteer een produit supplémentaire...</option>
                            {pralinesSuppl.map((praline) => (
                                <option value={praline[0]}>{praline[1]}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-span-1">
                        <label htmlFor="aangepastproduct" className="block text-sm font-medium text-gray-700 pb-1">
                         Aantal
                        </label>
                        <input
                              type="text"
                              name="aantalproduct"
                              id="aantalproduct"
                              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md w-1/2"
                              placeholder="Aantal..."
                              onChange={event => setPralineSupplAantal(event.target.value)}
                              value={pralineSupplAantal}
                            />  
                    </div>
                    <Button text="TOEVOEGEN" icon={<PlusIcon className="ml-auto mr-3 h-5 w-5" aria-hidden="true" />} type="primary" classes="h-3/4 w-auto ml-auto mt-auto" onClick={addProductSuppl} />
                </div>
            </div>
            <Spacer height={50} />
            <Divider />
            <Spacer height={25} />
            {carton.length ? <Table data={carton} fields={['Soort', 'Aantal', 'Gewicht', 'etiket']} edit={false} removeable={true} removeIcon={<XIcon className="ml-auto mr-3 h-5 w-5 text-red-500" aria-hidden="true" />} onClick={removeBox} /> : ''}
            <Spacer height={15} />
            {cartonSuppl.length ? <Table data={cartonSuppl} fields={['Titel', 'Aantal']} edit={false} removeable={true} removeIcon={<XIcon className="ml-auto mr-3 h-5 w-5 text-red-500" aria-hidden="true" />} onClick={removeSuppl} />: ''}       
            <Spacer height={50} />
    </div>
)};