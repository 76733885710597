import Sidebar from './components/Sidebar';
import Clients from './components/Clients';
import Dozen from './components/Dozen';
import Catalogus from './components/Catalogus';
import AddClient from './components/AddClient';
import AddSchool from './components/AddSchool';
import AddClass from './components/AddClass';
import ViewClient from './components/ViewClient';
//import AddCartonClient from './components/AddCartonClient';
import ViewCartonClient from './components/ViewCartonClient';
import ViewClass from './components/ViewClass';
import EditClient from './components/EditClient';
import EditClass from './components/EditClass';
import ViewCartonClass from './components/ViewCartonClass';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Login from './components/Login';
import { useEffect, useState } from 'react';
//import {unauthorized} from './services/refresh';

const ClientComponent = <Clients />
const DozenComponent = <Dozen />
const CatalogusComponent = <Catalogus />
const addClientComponent = <AddClient />
const viewClientComponent = <ViewClient />
const addSchoolComponent = <AddSchool />
const addClassComponent = <AddClass />
//const cartonAddClientID = <AddCartonClient />
const clientCartonViewID = <ViewCartonClient /> 
const viewClassComponent = <ViewClass />
const editClientComponent = <EditClient />
const editClassComponent = <EditClass />
const classCartonViewID = <ViewCartonClass />



export default function App() {
    const [loggedin, setLoggedin] = useState("false")
    const unauthorized = async() => {
        fetch('https://api.leonidas.mylleinformatics.be/refresh/', {
            method: 'post',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('refreshtoken') },
        }).then(r => r.json())
            .then(token => {
            if (token.access_token){
              localStorage.setItem("accesstoken", token.access_token)
              setLoggedin("true");
            }
            else {
              setLoggedin("false");
            }
        })
        .catch(function(error) {
            setLoggedin("false");
      })
    }

    useEffect(() => {
        const authCheck = async() => {
              await fetch('https://api.leonidas.mylleinformatics.be/authcheck/', 
              { 
                method: 'get',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('accesstoken')
                }
              })
              .then((response) => {
                if (response.status == 401) {
                    //setLoggedin(unauthorized());
                    unauthorized()
                } else {
                    if (response.ok) {
                        setLoggedin("true")
                    }
                }
              })
              .catch(function(error) {
                    //setLoggedin(unauthorized());
                    unauthorized()
              })
        }
        authCheck();
    }, [])
    if (loggedin == "false") {
        return <Login setLoggedin={setLoggedin} />
    }
    return (
        <Router>
            <Route path="/" exact render={(props) => (
                <Sidebar content={ClientComponent} />
            )}>
            </Route>
            <Route path="/dozen" render={(props) => (
                <Sidebar content={DozenComponent} />
            )}>
            </Route>
            <Route path="/catalogus" render={(props) => (
                <Sidebar content={CatalogusComponent} />
            )}>
            </Route>
            <Route path="/addClient" render={(props) => (
                <Sidebar content={addClientComponent} />
            )}>
            </Route>
            <Route path="/addSchool" render={(props) => (
                <Sidebar content={addSchoolComponent} />
            )}>
            </Route>
            <Route path="/addClass" render={(props) => (
                <Sidebar content={addClassComponent} />
            )}>
            </Route>
            <Route path="/viewClient/:id" render={(props) => (
                <Sidebar content={viewClientComponent} />
            )}>
            </Route>
            <Route path="/editClient/:id" render={(props) => (
                <Sidebar content={editClientComponent} />
            )}>
            </Route>
            <Route path="/viewClass/:id" render={(props) => (
                <Sidebar content={viewClassComponent} />
            )}>
            </Route>
            <Route path="/editClass/:id" render={(props) => (
                <Sidebar content={editClassComponent} />
            )}>
            </Route>
            <Route path="/carton/view/client/:id/:client" render={(props) => (
                <Sidebar content={clientCartonViewID} />
            )}>
            </Route>
            <Route path="/carton/view/class/:id/:client" render={(props) => (
                <Sidebar content={classCartonViewID} />
            )}>
            </Route>
            {/* <Route path="/carton/add/client/:id" render={(props) => (
                <Sidebar content={cartonAddClientID} />
            )}>
            </Route> */}
            
        </Router>  
    )
}