import { Link } from 'react-router-dom'; 

  export default function EditTable({data, fields, edit, link, onChange, removeable, removeIcon, onClick, type}) {
    const reactStringReplace = require('react-string-replace')
    return (
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    {fields.map((field, fieldIdx) => (
                      <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {field}
                        </th>
                    ))}
                    {edit && <th
                      scope="col"
                      className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          ACTIE
                        </th>
                    }
                    {removeable && <th
                      scope="col"
                      className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          VERWIJDEREN
                        </th>
                    }
                  </tr>
                </thead>
                <tbody>
                  {data.map((client, clientIdx) => (
                    <tr key={client[clientIdx]} className={clientIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                      {typeof client[0] === 'string'
                        ? <>
                         <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          <input
                              type="text"
                              name="email"
                              id="email"
                              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              placeholder="you@example.com"
                              aria-describedby="email-description"
                            />  
                         </td>
                         <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{client[1]}</td>
                        </>
                        : <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          <input
                              type="text"
                              identifier={client[0]}
                              name={"inputfield" + client[0]}
                              id={"inputfield" + client[0]}
                              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md w-1/2"
                              value={client[1]}
                              onChange={e => onChange(e)}
                            />  
                          </td>
                      }
                      {typeof client[2] !== 'undefined' && <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {type === "replace" 
                      ? 
                      reactStringReplace(client[2], 'LINEBREAK', (match, i) => (
                        <br />
                      ))
                      : client[2]
                      }</td>}
                      {typeof client[3] !== 'undefined' && <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{client[3]}</td>}
                      {typeof client[4] !== 'undefined' && <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{client[4]}</td>}
                      {typeof client[5] !== 'undefined' && <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{client[5]}</td>}
                      {edit && <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <Link to={link + client[0]} className="text-indigo-600 hover:text-indigo-900">
                         Inkijken/aanpassen
                        </Link>
                      </td>}
                      {removeable && <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <button onClick={() => onClick(client[0], client[2])}>
                          {removeIcon}
                        </button>
                      </td>}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }