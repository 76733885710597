import { Link } from 'react-router-dom'


export default function emptyState({ text, link, icon}) {
    return (
      <Link to={link}>
        <button
          type="button"
          className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
          {icon}
          <span className="mt-2 block text-sm font-medium text-gray-900">{text}</span>
        </button>
      </Link>
    )
  }