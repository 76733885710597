import {React, useState, useEffect} from 'react';
import Title from './individual/Title';
import Spacer from './individual/Spacer';
import Button from './individual/Button';
import  { useHistory } from 'react-router-dom'
import {
  PlusIcon,
  XIcon
} from '@heroicons/react/solid'

export default function AddSchool() {
  const [newClient, setNewClient] = useState('')
  const history = useHistory();

  const addClient = async(type) => {
  const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('accesstoken') },
      body: JSON.stringify({ klantnaam: newClient })
  };
  const res = await fetch('https://api.leonidas.mylleinformatics.be/school/add/', requestOptions);
  const data = await res.json();
  history.push('/addClass/' + data);
  }
  
  return ( 
      <div className="mx-24">
      <Spacer height={50} />
      <Title text="School toevoegen" type="head" />
      <Spacer height={15} />
       <div id="addDefaultClient">
              <label htmlFor="klantnaam" className="block text-sm font-medium text-gray-700">
              Schoolnaam
              </label>
              <div className="mt-1">
              <input
                  type="text"
                  name="klantnaam"
                  id="klantnaam"
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  placeholder="Schoolnaam"
                  onChange={event => setNewClient(event.target.value)}
              />
              </div>
          </div>
          <Spacer height={15} />
          <div className="float-right">
              <span className="ml-3" />
              <Button text="Opslaan en klas toevoegen" icon={<PlusIcon className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />} type="primary" onClick={addClient}/>
          </div>
      </div>
  )
};