import React, { useState } from 'react';


export default function LoginScreen({ setLoggedin }) {
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();

    const onSubmitClick = (e)=>{
        e.preventDefault()
        let opts = {
          'username': username,
          'password': password
        }
        fetch('https://api.leonidas.mylleinformatics.be/login/', {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(opts)
        }).then(r => r.json())
          .then(token => {
            if (token.access_token && token.refresh_token){
              localStorage.setItem("refreshtoken", token.refresh_token)
              localStorage.setItem("accesstoken", token.access_token)
              setLoggedin("true")
            }
            else {
              alert("Please type in correct username/password")
            }
          })
      }

    return (
      <>
        <div className="min-h-full flex">
          <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
            <div className="mx-auto w-full max-w-sm lg:w-96">
              <div>
                <img
                  className="h-24 w-auto"
                  src={window.location.protocol + '//' + window.location.host + '/assets/img/logo.jpg'}
                  alt="Logo"
                />
                <h2 className="mt-6 text-3xl font-extrabold text-gray-900">Log in op jouw account</h2>
                <p className="mt-2 text-xs text-gray-600">
                  of {' '}
                  <a href="https://mylleinformatics.be/#contact" className="font-medium text-indigo-600 hover:text-indigo-500">
                    contacteer ons {' '}
                  </a>
                  om een gebruikersacount aan te maken.
                </p>
              </div>
  
              <div className="mt-8">
                <div className="mt-6">
                  <form action="#" method="POST" className="space-y-6" onSubmit={onSubmitClick}>
                    <div>
                      <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        E-mailadres
                      </label>
                      <div className="mt-1">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          onChange={e => setUserName(e.target.value)}
                        />
                      </div>
                    </div>
  
                    <div className="space-y-1">
                      <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                        Wachtwoord
                      </label>
                      <div className="mt-1">
                        <input
                          id="password"
                          name="password"
                          type="password"
                          autoComplete="current-password"
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          onChange={e => setPassword(e.target.value)}  
                        />
                      </div>
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Sign in
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden lg:block relative w-0 flex-1">
            <img
              className="absolute inset-0 h-full w-full object-cover"
              src={window.location.protocol + '//' + window.location.host + '/assets/img/login.jpg'}
              alt="LoginImage"
            />
          </div>
        </div>
      </>
    )
  }